import React from 'react'
import { Cpu } from "lucide-react"
export default function () {
  return (
    <header className="px-4 lg:px-6 h-16 flex items-center bg-white shadow-sm">
    <a className="flex items-center justify-center" href="#">
      <Cpu className="h-6 w-6 text-blue-600" />
      <span className="ml-2 text-xl font-bold text-gray-800">Nexa Nest</span>
    </a>
    <nav className="ml-auto flex gap-4 sm:gap-6">
      <a className="text-sm font-medium hover:text-blue-600 transition-colors" href="#services">
        Services
      </a>
      <a className="text-sm font-medium hover:text-blue-600 transition-colors" href="#about">
        About
      </a>
      <a className="text-sm font-medium hover:text-blue-600 transition-colors" href="#contact">
        Contact
      </a>
    </nav>
  </header>
  )
}
