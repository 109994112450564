import React from 'react'
import Header from '../components/header'
import Services from '../components/services'
import About from '../components/about'
import Contact from '../components/contact'
import Footer from '../components/footer'
import Hero from '../components/hero'

export default function LandingPage() {

  return (
    <div className="flex flex-col min-h-screen">
      <Header/>
      <main className="flex-1">
        <Hero/>
        <Services/>
        <About/>
        <Contact/>
      </main>
      <Footer/>
      
    </div>
  )
}