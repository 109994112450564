import React from 'react'
import { motion } from 'framer-motion'
import { ArrowRight } from 'lucide-react'

export default function About() {
    return (
        <section id="about" className="w-full py-20 md:py-32 lg:py-40 bg-gradient-to-br from-gray-50 to-white overflow-hidden">
            <div className="container px-4 md:px-6 mx-auto relative">
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-24"
                >
                    <div className="flex-1 max-w-2xl">
                        <h2 className="text-4xl md:text-5xl font-extrabold tracking-tight text-gray-900 mb-6 text-center">
                            About <span className="text-blue-600">Nexa Nest</span>
                        </h2>
                        <p className="text-gray-700 text-lg md:text-xl mb-6 leading-relaxed text-justify">
                            At Nexa Nest, we're passionate about leveraging cutting-edge technology to drive unprecedented business growth. Our team of experts combines innovation with practical solutions to help your business thrive in the ever-evolving digital landscape.
                        </p>
                        <p className="text-gray-700 text-lg md:text-xl mb-8 leading-relaxed text-justify">
                            From state-of-the-art e-commerce platforms to AI-powered tools, we're here to guide you through every step of your digital transformation journey, ensuring you stay ahead of the curve.
                        </p>
                        <div className="text-center">
                            <motion.a
                                href="#contact"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition-colors duration-300"
                            >
                                Learn More About Us
                                <ArrowRight className="ml-2 h-5 w-5" />
                            </motion.a>
                        </div>
                    </div>
                    <div className="flex-1 w-full max-w-lg">
                        <motion.svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                            initial={{ rotate: -10, scale: 0.9 }}
                            animate={{ rotate: 0, scale: 1 }}
                            transition={{ duration: 1, ease: "easeOut" }}
                            className="w-full h-auto"
                        >
                            <defs>
                                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                    <stop offset="0%" stopColor="#3B82F6" />
                                    <stop offset="100%" stopColor="#2563EB" />
                                </linearGradient>
                            </defs>
                            <path
                                fill="url(#gradient)"
                                d="M44.7,-76.4C58.8,-69.2,71.8,-59.1,79.6,-45.8C87.4,-32.6,90,-16.3,88.5,-0.9C87,14.6,81.4,29.2,74.1,43.2C66.7,57.2,57.6,70.6,45,78.3C32.4,86,16.2,88,-0.4,88.7C-17,89.4,-34,88.8,-47.1,81.2C-60.2,73.6,-69.4,59,-76.2,43.9C-83,28.8,-87.4,14.4,-87.6,-0.1C-87.8,-14.6,-83.8,-29.2,-76.2,-41.7C-68.6,-54.2,-57.3,-64.7,-44.1,-72.5C-30.8,-80.3,-15.4,-85.4,0.2,-85.8C15.8,-86.2,31.6,-81.9,44.7,-76.4Z"
                                transform="translate(100 100)"
                            />
                            <text x="70" y="120" fontFamily="Arial" fontSize="24" fill="white" fontWeight="bold">Nexa Nest</text>
                        </motion.svg>
                    </div>
                </motion.div>
                <div className="absolute top-1/2 left-0 w-72 h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                <div className="absolute top-1/2 right-0 w-72 h-72 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
            </div>
        </section>
    )
}