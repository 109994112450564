import React from 'react'
export default function Footer() {
  return (
    <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs sm:text-sm text-gray-600">© {new Date().getFullYear()} Nexa Nest. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs sm:text-sm hover:underline underline-offset-4 text-gray-600" href="/privacy-policy">
            Terms of Service
          </a>
          <a className="text-xs sm:text-sm hover:underline underline-offset-4 text-gray-600" href="/t&c">
            Privacy Policy
          </a>
        </nav>
      </footer>
  )
}
