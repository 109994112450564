import React from 'react'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { ArrowRight, Mail, Phone, MapPin } from 'lucide-react'
import { motion } from "framer-motion"

export default function Contact() {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <section id="contact" className="w-full py-20 md:py-32 bg-gradient-to-b from-white to-gray-50 relative overflow-hidden">
      <div className="absolute inset-0 bg-grid-slate-200 [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0.5))] -z-10"></div>
      <div className="container px-4 md:px-6 mx-auto relative z-10">
        <motion.div 
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5 }}
          variants={fadeIn}
          className="text-center mb-16"
        >
          <h2 className="text-4xl sm:text-5xl md:text-6xl font-extrabold tracking-tight text-gray-900 mb-4">
            Get in <span className="text-blue-600">Touch</span>
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Ready to elevate your business? Let's start a conversation about how Nexa Nest can transform your digital presence.
          </p>
        </motion.div>
        <div className="grid gap-12 lg:grid-cols-2">
          <motion.div 
            className="flex flex-col space-y-6 bg-white p-8 rounded-2xl shadow-lg"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.2 }}
            variants={fadeIn}
          >
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Contact Information</h3>
            <p className="text-gray-600 text-lg">
              We're here to help you take your business to new heights. Reach out for a free consultation on how Nexa Nest can help you achieve your goals.
            </p>
            <div className="flex items-center space-x-4 text-gray-600">
              <Mail className="h-6 w-6 text-blue-600" />
              <span className="text-lg">info@nexanestltd.co.uk</span>
            </div>
            <div className="flex items-center space-x-4 text-gray-600">
              <Phone className="h-6 w-6 text-blue-600" />
              <span className="text-lg">+44 7783477876</span>
            </div>
            <div className="flex items-center space-x-4 text-gray-600">
              <MapPin className="h-6 w-6 text-blue-600" />
              <span className="text-lg">London, United Kingdom</span>
            </div>
          </motion.div>
          <motion.form 
            className="space-y-6 bg-white p-8 rounded-2xl shadow-lg"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={fadeIn}
          >
            <Input placeholder="Your Name" className="text-lg py-3" />
            <Input placeholder="Your Email" type="email" className="text-lg py-3" />
            <Textarea placeholder="Your Message" className="text-lg py-3" rows={4} />
            <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white text-lg py-3 rounded-full transition-all duration-300 transform hover:scale-105">
              Send Message
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </motion.form>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-gray-50 to-transparent"></div>
    </section>
  )
}