import React from 'react'
import { ShoppingCart, Bot, Code, Database, Cpu } from "lucide-react"
import { motion } from "framer-motion"

export default function Services() {
    const services = [
        { icon: ShoppingCart, title: "E-commerce Solutions", description: "Custom e-commerce platforms tailored to your business needs." },
        { icon: Bot, title: "AI Business Tools", description: "Cutting-edge AI solutions to streamline your operations." },
        { icon: Code, title: "Software Development", description: "Bespoke software solutions for web and mobile platforms." },
        { icon: Database, title: "Data Processing", description: "Efficient data processing and analytics services." },
        { icon: Cpu, title: "POS Systems", description: "Modern point-of-sale systems for retail and hospitality." },
        { icon: Bot, title: "Chatbot Development", description: "Intelligent chatbots to enhance customer engagement." },
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    return (
        <section id="services" className="w-full py-20 md:py-28 lg:py-36 bg-gradient-to-b from-white to-gray-50">
            <div className="container px-4 md:px-6 mx-auto">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-center mb-16"
                >
                    <h2 className="text-4xl sm:text-5xl md:text-6xl font-extrabold tracking-tight text-gray-900 mb-4">
                        Our <span className="text-blue-600">Services</span>
                    </h2>
                    <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                        Empowering your business with cutting-edge solutions tailored to your needs.
                    </p>
                </motion.div>
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="grid gap-8 sm:gap-12 sm:grid-cols-2 lg:grid-cols-3"
                >
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            variants={itemVariants}
                            className="flex flex-col items-center p-8 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100"
                        >
                            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6">
                                <service.icon className="h-8 w-8 text-blue-600" />
                            </div>
                            <h3 className="text-2xl font-bold text-gray-900 mb-4">{service.title}</h3>
                            <p className="text-gray-600 text-center leading-relaxed">
                                {service.description}
                            </p>
                            <a href="#" className="mt-6 text-blue-600 font-semibold hover:text-blue-800 transition-colors duration-300">
                                Learn More →
                            </a>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-gray-50 to-transparent"></div>
        </section>
    )
}