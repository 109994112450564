import React from 'react'
import { Button } from "../components/ui/button"
import { motion } from "framer-motion"

export default function Hero() {
  return (
    <section className="relative w-full overflow-hidden bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 py-20 md:py-28 lg:py-32 xl:py-40">
      <div className="absolute inset-0 bg-grid-slate-200 [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0.5))] -z-10"></div>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="container px-4 md:px-6 mx-auto"
      >
        <div className="flex flex-col items-center space-y-8 text-center">
          <div className="space-y-4 max-w-4xl">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              Innovate, Integrate, Elevate with Nexa Nest
            </h1>
            <p className="mx-auto max-w-[800px] text-slate-700 text-lg sm:text-xl md:text-2xl leading-relaxed">
              Your one-stop solution for e-commerce, AI-powered business tools, IT consultancy, and data processing. Elevate your business to new heights.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <Button className="bg-blue-600 hover:bg-blue-700 text-white text-base sm:text-lg px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl">
              Get Started
            </Button>
            <Button variant="outline" className="text-blue-600 border-2 border-blue-600 hover:bg-blue-50 text-base sm:text-lg px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105">
              Learn More
            </Button>
          </div>
        </div>
      </motion.div>
      <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent"></div>
    </section>
  )
}